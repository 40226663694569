.overlay,
.modal {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}

@keyframes overlay-in {
  0% {
    scale: 0 0.004;
  }
  33% {
    scale: 1 0.004;
  }
  66%,
  100% {
    scale: 1 1;
  }
}

.overlay {
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(0 0 0 / 75%);
}

.overlay.open {
  visibility: visible;
  opacity: 1;
  animation: overlay-in 1s both;
  border-radius: 12px;
}

@keyframes modal-in {
  0%,
  66% {
    opacity: 0;
    visibility: hidden;
    translate: -50% -40%;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

.modal {
  z-index: 2;
  width: 520px;
  top: 50%;
  left: 50%;
  background: #ffffff;
  border-radius: 12px;
  padding: 0 40px 50px;
  translate: -50% -50%;
  box-shadow: 0 10px 30px rgb(0 0 0 / 24%);
}

.overlay.open .modal {
  opacity: 1;
  visibility: visible;
  animation: modal-in 1s;
}

.close-button {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.modal textarea {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

.login-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #218838;
}

.error {
  border: 2px solid red !important;
}

.parsed-resume {
  padding: 20px;
}

.parsed-resume h1 {
  text-align: center;
  margin-bottom: 20px;
}

.parsed-resume table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
}

.parsed-resume table,
.parsed-resume th,
.parsed-resume td {
  border: 1px solid #ddd;
  padding: 8px;
}

.parsed-resume th {
  text-align: left;
}
