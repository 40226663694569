.dashboard {
  padding: 40px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 1000px;
  margin: 40px auto;
  color: #fff;
  font-size: 1.2rem;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

p {
  font-size: 1.5rem;
  margin-bottom: 30px;
}

.file-upload {
  margin-top: 20px;
  color: #777;
}

.file-upload input[type="file"] {
  display: block;
  margin-bottom: 10px;
  width: 100%;
  font-size: 1rem;
}

.file-upload button {
  background-color: var(--color-primary);
  color: #f9f9f9;
  border: none;
  padding: 15px 30px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.file-upload button:hover {
  background-color: darken(var(--color-primary), 10%);
}

.input-name {
  color: #fff;
}
